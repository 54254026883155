import { createApp } from 'vue';
import fontAwesome from '@fortawesome/fontawesome-free/css/all.min.css';
import styles from './styles/main.scss';

import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
  .use(styles)
  .use(fontAwesome)
  .use(store)
  .use(router)
  .mount('#app');
