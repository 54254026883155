<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
      <a class="navbar-item" href="https://joycoder.top">
        <img src="images/avatar.png">
      </a>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu is-align-content-center">
      <div class="navbar-start">
        <router-link to="/" class="navbar-item">Inicio</router-link>
        <router-link to="/about" class="navbar-item">About</router-link>
      </div>

    </div>
  </div>
</nav>
</template>

<script>
export default {

};
</script>

<style>

</style>
