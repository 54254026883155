<template>
  <div class="home my-5">
    <h1 class="title is-1">Disfrutando de la programación.</h1>
    <p>¡Bienvenid@! Mi nombre es Tonny y soy ingeniero de software full-stack</p>
    <p>
      Creo apps en directo en Twitch y luego edito el contenido para que lo puedas ver en Youtube
    </p>
    <p>
      <a href="https://twitch.tv/joycoder" class="social-link">
        <i class="fab fa-twitch has-text-purple is-size-1"></i>
        <br/>
        <span>Visita el canal de JoyCoder en Twitch</span>
      </a>
    </p>
    <p>
      <a href="https://www.youtube.com/channel/UCuSB7-8qWTSv65IbNR9WG-g" class="social-link">
        <i class="fab fa-youtube has-text-red is-size-1"></i>
        <br/>
        <span>
         Visita el canal de JoyCoder en Youtube
        </span>
      </a>
    </p>
    <p>
      <a href="https://github.com/thejoycoder" class="social-link">
        <i class="fab fa-github has-text-white is-size-1"></i>
        <br/>
        <span>Visita el repositorio de JoyCoder en Github</span>
      </a>
    </p>
  </div>
  <LatestProjects/>
</template>

<script>
import LatestProjects from '../components/LatestProjects.vue';

export default {
  name: 'Home',
  components: {
    LatestProjects,
  },
};
</script>

<style scoped lang="scss">
.home {
  text-align: center;
}
p{
  padding-bottom: 1.5rem;
}
.social-link {
  font-weight: bold;
  display: block;
  max-width: 70%;
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 0 auto;
  padding: 1rem;
}
.social-link:hover {
  background-color: #101111;
  color: #fff;
}
</style>
