<template>
  <Navbar>
  </Navbar>
  <router-view class="container my-3"/>
</template>

<style lang="scss">
</style>

<script>
import Navbar from './components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  setup() {

  },
};
</script>
