<template>
  <div class="container latest-projects" v-if="repos.length">
    <h2 class="has-text-centered subtitle">Últimos repositorios actualizados:</h2>
    <div class="repos">
      <div class="repo" v-for="repo in repos" :key="repo.id" :title="repo.description">
        <a :href="repo.url" >{{repo.name}}</a>
        <br/>
        <p class="description">{{repo.description}}</p>
        <span>{{repo.lastUpdate}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { format, register } from 'timeago.js';

function localeFunc(number, index) {
  return [
    ['justo ahora', 'en un rato'],
    ['hace %s segundos', 'en %s segundos'],
    ['hace 1 minuto', 'en 1 minuto'],
    ['hace %s minutos', 'en %s minutos'],
    ['hace 1 hora', 'en 1 hora'],
    ['hace %s horas', 'en %s horas'],
    ['hace 1 día', 'en 1 día'],
    ['hace %s días', 'en %s días'],
    ['hace 1 semana', 'en 1 semana'],
    ['hace %s semanas', 'en %s semanas'],
    ['hace 1 mes', 'en 1 mes'],
    ['hace %s meses', 'en %s meses'],
    ['hace 1 año', 'en 1 año'],
    ['hace %s años', 'en %s años'],
  ][index];
}

// register your locale with timeago
register('es_ES', localeFunc);

export default {

  name: 'LatestProjects',
  props: {
    msg: String,
  },

  setup() {
    const repos = ref([]);

    async function getGithubRepos() {
      try {
        const response = await axios
          .get('https://api.github.com/orgs/thejoycoder/repos?sort=updated&direction=desc&per_page=10');
        const allRepos = response.data;
        repos.value = allRepos.map((repo) => ({
          id: repo.id,
          name: repo.name,
          url: repo.html_url,
          description: repo.description,
          lastUpdate: format(new Date(repo.updated_at), 'es_ES'),
        }));
      } catch (error) {
        console.error(error);
      }
    }
    getGithubRepos();
    return {
      getGithubRepos,
      repos,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.repos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.repo {
  display:block;
  border: 1px solid #fff;
  padding: 1rem;
  margin: 0.2rem;
  text-align: center;
  border-radius: 5px;

  a {
    display: block;
  }
  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:20rem;
  }
}
</style>
